import React from 'react';
import styled, { keyframes } from 'styled-components';

type RippleLoaderProps = {
  color?: string;
};

export const RippleLoader: React.FC<RippleLoaderProps> = ({ color }) => {
  return (
    <Ripple aria-label="Loading">
      <FirstChild color={color} />
      <SecondChild color={color} />
    </Ripple>
  );
};

const rippleAnimationKeyframes = keyframes`
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
`;

const FirstChild = styled.div<{ color?: string }>`
  position: absolute;
  border: 4px solid ${({ color }) => color ?? '#fff'};
  opacity: 1;
  border-radius: 50%;
  animation: ${rippleAnimationKeyframes} 1s cubic-bezier(0, 0.2, 0.8, 1)
    infinite;
`;

const SecondChild = styled(FirstChild)`
  animation-delay: -0.5s;
`;

const Ripple = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
`;
