import { useAuth } from '@parsleyhealth/front-end-utils';
import React, { ReactElement } from 'react';
import { Redirect, useLocation } from 'react-router';

interface Props {
  children: ReactElement;
}

const LoginRequired: React.FC<Props> = ({ children }) => {
  const { firebaseUser } = useAuth();
  const { pathname } = useLocation();
  if (!firebaseUser) {
    return null;
  }

  if (firebaseUser.isAnonymous) {
    const to =
      pathname !== '/' ? `/login?redirect=${encodeURI(pathname)}` : '/login';

    return <Redirect to={to}></Redirect>;
  }

  return <>{children}</>;
};

export default LoginRequired;
