import { gql, useQuery } from '@apollo/client';
import { useAuth } from '@parsleyhealth/front-end-utils';
// import { LoadingPage } from 'components/pages/LoadingPage';
import { DateTime } from 'luxon';
import React, { createContext, useContext, useEffect, useState } from 'react';

import {
  currentPerson,
  currentPerson_currentPapiPerson
} from '../types/generated/currentPerson';
import { useFirestore } from './firestore';
import { useFirestoreAuth } from './firestoreAuth';

export const CURRENT_PERSON_QUERY = gql`
  query currentPerson {
    currentPapiPerson {
      id
      email
      firstName
      lastName
      dateOfBirth
      stripeCustomerID
      biologicalSex
      genderIdentity
      addressLine1
      addressLine2
      addressCity
      addressState
      addressPostalCode
      phoneNumber
      center {
        state
      }
      currentPlan {
        plan {
          id
          product {
            type
          }
        }
        paymentStatus
      }
    }
  }
`;

enum StripeSubscriptionStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

enum StripePaymentStatus {
  FAILED = 'failed',
  PAID = 'paid',
  PENDING = 'pending'
}

type FirestoreUserData = {
  earlyAccessLabs?: boolean;
  labInfoSharingPolicyAcceptedAt?: DateTime;
  labTextMessagePolicyAcceptedAt?: DateTime;
  paymentStatus?: StripePaymentStatus;
  phoneVerified?: boolean;
  routinesTextMessagePolicyAcceptedAt?: DateTime;
  subscriptionID?: string;
  subscriptionStatus?: StripeSubscriptionStatus;
};

const defaultFirestoreData = {
  earlyAccessLabs: false,
  labInfoSharingPolicyAcceptedAt: undefined,
  labTextMessagePolicyAcceptedAt: undefined,
  paymentStatus: undefined,
  phoneVerified: false,
  routinesTextMessagePolicyAcceptedAt: undefined,
  subscriptionStatus: StripeSubscriptionStatus.ACTIVE,
  subscriptionID: ''
};

export type InsightsUser = currentPerson_currentPapiPerson &
  FirestoreUserData & {
    age: number;
    hasActiveSubscription: boolean;
  };

export const CurrentPersonContext = createContext<InsightsUser>(
  {} as InsightsUser
);

export const CurrentPersonProvider: React.FC = ({ children }) => {
  const { isLoggedIn, logout } = useAuth();
  const firestore = useFirestore();
  const { firestoreUser } = useFirestoreAuth();
  const [fsUserData, setFsUserData] = useState<FirestoreUserData | undefined>();
  const [loadingFsUserData, setloadingFsUserData] = useState<boolean>(true);
  const { data, error, loading, refetch } = useQuery<currentPerson>(
    CURRENT_PERSON_QUERY,
    {
      fetchPolicy: 'standby',
      pollInterval: 60000
    }
  );
  const user = data?.currentPapiPerson;

  // Copies whatever is in PAPI to FS insightsUser.
  // It is to have access to name an email in CF
  // Also make finding people easier
  useEffect(() => {
    if (user && !error && firestoreUser) {
      firestore.db
        .collection('insightsUsers')
        .doc(user.id)
        .set(
          {
            firstName: user.firstName,
            email: user.email,
            stripeCustomerID: user.stripeCustomerID,
            addressState: user.center?.state,
            biologicalSex: user.biologicalSex,
            genderIdentity: user.genderIdentity || null
          },
          { merge: true }
        )
        .catch((error) => console.log(error));

      firestore.db
        .collection('attributes')
        .doc(user.id)
        .set(
          {
            user_biologicalSex: user.biologicalSex
          },
          { merge: true }
        )
        .catch((error) => console.log(error));
    }
  }, [user, error, firestore.db, firestoreUser]);

  useEffect(() => {
    if (isLoggedIn) {
      refetch();
    }
  }, [isLoggedIn, refetch]);

  useEffect(() => {
    if (isLoggedIn && user && !fsUserData && !firestoreUser?.isAnonymous) {
      firestore.db
        .collection('insightsUsers')
        .doc(user.id)
        .onSnapshot(
          (doc) => {
            const data = doc.data();
            setloadingFsUserData(false);
            if (data) {
              window.analytics.identify(user.id, {
                email: user.email,
                isSprig: user.email.match(/.*parsleyhealth.com/) ? 'yes' : 'no',
                currentPlanPaymentStatus: user.currentPlan?.paymentStatus,
                currentPlanProductType: user.currentPlan?.plan.product.type,
                state: user.addressState,
                hasActiveSubscription:
                  data.subscriptionStatus === StripeSubscriptionStatus.ACTIVE
                    ? 'yes'
                    : 'no'
              });

              setFsUserData({
                ...defaultFirestoreData,
                ...data
              });
            }
          },
          (e) => {
            console.log('Error in currentPerson', e);
            //noop this
          }
        );
    }
  }, [isLoggedIn, user, firestore.db, fsUserData, firestoreUser]);

  useEffect(() => {
    if (error) {
      console.log('logging out');
      logout();
    }
  });

  // This is an error. We are not loading and have user
  if (!loading && !user) {
    return <h1>Error</h1>;
  }

  if (user && !loadingFsUserData && fsUserData) {
    const age = user.dateOfBirth
      ? Math.floor(-DateTime.fromISO(user.dateOfBirth).diffNow('years').years)
      : 30;

    const insightsUser = {
      ...fsUserData,
      ...user,
      age: age,
      hasActiveSubscription:
        fsUserData?.subscriptionStatus === StripeSubscriptionStatus.ACTIVE
    };

    return (
      <CurrentPersonContext.Provider value={insightsUser}>
        {children}
      </CurrentPersonContext.Provider>
    );
  }

  return null;
};

export const useCurrentPerson = (): InsightsUser => {
  const context = useContext(CurrentPersonContext);
  if (!context) {
    throw new Error(
      'useCurrentPerson must be used within CurrentPersonProvider'
    );
  }
  return context;
};
