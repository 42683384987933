import React, { useState, useEffect, useContext } from 'react';

import Firebase from '../utils/firebase';

const FirestoreContext = React.createContext<Firebase>({} as Firebase);

export const FirestoreProvider: React.FC = (props) => {
  const [firebase, setFirebase] = useState<Firebase>();
  useEffect(() => {
    if (!firebase) {
      setFirebase(new Firebase());
    }
  }, [firebase]);

  if (firebase) {
    return (
      <FirestoreContext.Provider value={firebase}>
        {props.children}
      </FirestoreContext.Provider>
    );
  }

  return null;
};

export const useFirestore = (): Firebase => {
  const context = useContext(FirestoreContext);
  if (!context) {
    throw new Error('useFirestore must be used within FirestoreProvider');
  }
  return context;
};
