import { useAuth } from '@parsleyhealth/front-end-utils';
import { useFirestore } from 'context/firestore';
import firebaseApp from 'firebase';
import useGetFirestoreToken from 'hooks/useFirestoreToken';
import React, { useEffect, useContext, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

export interface FirestoreAuthContextProps {
  firestoreUser: firebaseApp.User | null | undefined;
  isAuthenticatingFirestore: boolean;
  isLoggedInFirestore: boolean;
}

const FirestoreAuthContext = React.createContext<FirestoreAuthContextProps>({
  isAuthenticatingFirestore: true,
  isLoggedInFirestore: false,
  firestoreUser: undefined
});

export const FirestoreAuthProvider: React.FC = ({ children }) => {
  const firestore = useFirestore();
  const [isAuthenticatingFirestore, setIsAuthenticatingFirestore] =
    useState(true);
  const { firebaseUser, sessionExpired } = useAuth();
  const { data: tokenData } = useGetFirestoreToken();
  const [user, loading, error] = useAuthState(firestore.auth);

  const token = tokenData?.getFirestoreToken;

  useEffect(() => {
    if (loading && !error) {
      setIsAuthenticatingFirestore(false);
    }
  }, [loading, error]);

  useEffect(() => {
    if (token && firestore) {
      firestore
        .signInWithCustomToken(token)
        .then(() => {
          setIsAuthenticatingFirestore(false);
        })
        .catch((error) => {
          console.log('FS Token Auth Error!');
          console.log(error);
        });
    }
  }, [token, firestore, firebaseUser?.uid]);

  useEffect(() => {
    if (sessionExpired) {
      firestore.doSignOut();
    }
  }, [sessionExpired, firestore]);

  const context = {
    isLoggedInFirestore: !!user && user !== null && !user.isAnonymous,
    firestoreUser: user,
    isAuthenticatingFirestore
  };

  if (!context.isLoggedInFirestore || !user || isAuthenticatingFirestore) {
    return null;
  }

  return (
    <FirestoreAuthContext.Provider value={context}>
      {children}
    </FirestoreAuthContext.Provider>
  );
};

export const useFirestoreAuth = (): FirestoreAuthContextProps => {
  const context = useContext(FirestoreAuthContext);

  if (!context) {
    throw new Error(
      'useFirestoreAuth must be used within FirestoreAuthProvider'
    );
  }

  return context;
};
