//import firebase from 'firebase'
import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import {
  REACT_APP_FIRESTORE_API_KEY,
  REACT_APP_FIRESTORE_PROJECT_ID
} from '../constants/env';

const firestoreConfig = {
  apiKey: REACT_APP_FIRESTORE_API_KEY,
  authDomain: `${REACT_APP_FIRESTORE_PROJECT_ID}.firebaseapp.com`,
  projectId: REACT_APP_FIRESTORE_PROJECT_ID,
  databaseURL: `https://${REACT_APP_FIRESTORE_PROJECT_ID}.firebaseio.com/`
};

class Firebase {
  auth: firebase.auth.Auth;
  db: firebase.firestore.Firestore;
  app: firebase.app.App;
  functions: firebase.functions.Functions;
  constructor() {
    const firestoreApp = firebase.initializeApp(firestoreConfig, 'firestore');
    this.auth = firestoreApp.auth();
    this.db = firestoreApp.firestore();
    this.app = firestoreApp;
    this.functions = firestoreApp.functions();
  }

  signInWithCustomToken = (
    token: string
  ): Promise<firebase.User | undefined> => {
    return new Promise((resolve, reject) => {
      this.auth
        .signInWithCustomToken(token)
        .then((userCredential) => {
          const user = userCredential.user;
          if (user) {
            resolve(user);
          }
          resolve(undefined);
        })
        .catch((error) => {
          const errorMessage = error.message;
          reject({ user: undefined, error: errorMessage });
        });
    });
  };

  doSignOut = (): Promise<void> => this.auth.signOut();

  checkLoggedIn = async (): Promise<firebase.User | null> => {
    return new Promise<firebase.User | null>((resolve, reject) => {
      try {
        this.auth.onAuthStateChanged((user) => {
          resolve(user);
        });
      } catch (err) {
        reject(err);
      }
    });
  };

  getFsUserRef = (): firebase.firestore.DocumentReference | null => {
    if (this.auth.currentUser) {
      return this.db.collection('insights').doc(this.auth.currentUser.uid);
    }
    return null;
  };
}

export default Firebase;
