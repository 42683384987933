import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  ApolloProvider
} from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { useAuth } from '@parsleyhealth/front-end-utils';
import firebaseApp from 'firebase';
import React, { useMemo } from 'react';

import { REACT_APP_API_URL } from '../constants/env';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const makeGqlClient = (currentUser: firebaseApp.User | null) => {
  const cache = new InMemoryCache();
  const httpLink = new HttpLink({
    uri: `${REACT_APP_API_URL}/graphql`
  });

  const withToken = setContext(() => {
    return currentUser?.getIdToken().then((token: string) => {
      return {
        headers: { authorization: `Bearer ${token}` }
      };
    });
  });
  return new ApolloClient({
    link: ApolloLink.from([withToken, httpLink]),
    cache
  });
};
export const ApolloGqlProvider: React.FC = ({ children }) => {
  const { firebaseUser } = useAuth();
  const client = useMemo(() => makeGqlClient(firebaseUser), [firebaseUser]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
