import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getLocalStorage, setLocalStorage } from 'utils/localStorage';

export interface LabRequirement {
  checked: boolean;
  key: string;
  text: string;
}

interface LabsActivationState {
  activationState?: string;
  isVideoEnded: boolean;
  kitId?: string;
  numReqsMet: number;
  phone?: string;
  requirements: LabRequirement[];
  stripeProductId?: string;
}

export interface LabsActivationContextState extends LabsActivationState {
  setLabsActivation: Dispatch<SetStateAction<LabsActivationState>>;
}

export const defaultRequirements = [
  {
    checked: false,
    key: 'personal',
    text: `I am collecting my own sample`
  },
  {
    checked: false,
    key: 'morning',
    text: `I am collecting my sample in the morning`
  },
  {
    checked: false,
    key: 'fasted',
    text: `I have fasted for 8 hours`
  },
  {
    checked: false,
    key: 'today',
    text: `I will send my sample back today`
  }
];

export const defaultLabsActivationState: LabsActivationContextState = {
  requirements: [
    {
      checked: false,
      key: 'personal',
      text: `I am collecting my own sample`
    },
    {
      checked: false,
      key: 'morning',
      text: `I am collecting my sample in the morning`
    },
    {
      checked: false,
      key: 'fasted',
      text: `I have fasted for 8 hours`
    },
    {
      checked: false,
      key: 'today',
      text: `I will send my sample back today`
    }
  ],
  isVideoEnded: false,
  numReqsMet: 0,
  activationState: undefined,
  kitId: undefined,
  setLabsActivation: (() => {}) as Dispatch<SetStateAction<LabsActivationState>>
};

export const LabsActivationContext =
  React.createContext<LabsActivationContextState>(defaultLabsActivationState);

export const LabsActivationProvider: React.FC = (props) => {
  const state = getLocalStorage<LabsActivationState>(
    'labsActivation',
    defaultLabsActivationState
  );

  const [labsActivation, setLabsActivation] =
    useState<LabsActivationState>(state);

  useEffect(() => {
    setLocalStorage<LabsActivationState>('labsActivation', labsActivation);
  }, [labsActivation]);

  if (!props) {
    return null;
  }

  return (
    <LabsActivationContext.Provider
      value={{
        ...labsActivation,
        setLabsActivation
      }}
    >
      {props.children}
    </LabsActivationContext.Provider>
  );
};
