import React from 'react';

const LockIcon: React.FC = () => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 17 21"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      height="13.5455"
      rx="1.5"
      stroke="currentColor"
      width="15.3636"
      x="0.5"
      y="6.95459"
    />
    <path
      d="M4.54688 7.36364V4.63636C4.54688 2.62806 6.17493 1 8.18324 1V1C10.1915 1 11.8196 2.62805 11.8196 4.63636V7.36364"
      stroke="currentColor"
    />
  </svg>
);

export { LockIcon };
