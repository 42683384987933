import { datadogRum } from '@datadog/browser-rum';
import { FirebaseProvider, AuthProvider } from '@parsleyhealth/front-end-utils';
import { FirestoreProvider } from 'context/firestore';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.css';
import App from './App';
import { ApolloGqlProvider } from './context/apollo';
import reportWebVitals from './reportWebVitals';

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID!,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN!,
  site: 'datadoghq.com',
  service: 'insights',
  env: process.env.REACT_APP_DATADOG_ENV!,
  version: process.env.REACT_APP_DATADOG_VERSION,
  replaySampleRate: 100,
  sampleRate: 100,
  trackInteractions: true,
  allowedTracingOrigins: [/https:\/\/.*\.parsleyhealth\.com/]
});

datadogRum.startSessionReplayRecording();

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
  authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL!,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!
};

if (process.env.REACT_APP_LOG_ROCKET_APP_ID) {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID!);
  setupLogRocketReact(LogRocket);
}

ReactDOM.render(
  <FirebaseProvider config={config}>
    <AuthProvider>
      <FirestoreProvider>
        <ApolloGqlProvider>
          <React.StrictMode>
            <Router
              getUserConfirmation={() => {
                /* Empty callback to block the default browser prompt */
              }}
            >
              <App />
            </Router>
          </React.StrictMode>
        </ApolloGqlProvider>
      </FirestoreProvider>
    </AuthProvider>
  </FirebaseProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
