import LoginRequired from 'components/layouts/LoggedInLayout';
import { Signup } from 'components/pages/SignupPage';
import PortalRouter from 'components/routes/PortalRouter';
import { CurrentPersonProvider } from 'context/currentPerson';
import { FirestoreAuthProvider } from 'context/firestoreAuth';
import React, { useEffect, Suspense } from 'react';
import {
  Switch,
  Route,
  useLocation,
  BrowserRouter as Router
} from 'react-router-dom';

import './App.css';
import { createClient } from './utils/habanero-client';

const RegisterPage = React.lazy(() => import('components/pages/RegisterPage'));
const LoginPage = React.lazy(() => import('components/pages/LoginPage'));
const ForgotPasswordPage = React.lazy(
  () => import('components/pages/ForgotPasswordPage')
);
const Logout = React.lazy(() => import('components/pages/Logout'));
const EmailVerification = React.lazy(
  () => import('components/pages/EmailVerification')
);
const UnauthenticatedQuestionnaireLayout = React.lazy(
  () => import('components/layouts/UnauthenticatedQuestionnaireLayout')
);

createClient();

// Segment Hooks
const usePageViews = () => {
  const location = useLocation();
  useEffect(() => {
    // For /labs, tracking page view in component to add properties
    location.pathname !== '/labs' && window.analytics.page(location.pathname);
  }, [location]);
};

const App: React.FC = () => {
  usePageViews();

  return (
    <div className="App">
      <Suspense fallback={<div />}>
        <Router
          getUserConfirmation={() => {
            /* Empty callback to block the default browser prompt */
          }}
        >
          <Switch>
            <Route exact path="/">
              <LoginPage />
            </Route>
            <Route path="/signup">
              <Signup />
            </Route>
            <Route path="/register">
              <RegisterPage />
            </Route>
            <Route path="/forgot-password">
              <ForgotPasswordPage />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/logout">
              <Logout />
            </Route>
            <Route path="/email-verification">
              <EmailVerification />
            </Route>
            <Route path="/quiz">
              <UnauthenticatedQuestionnaireLayout />
            </Route>
            <LoginRequired>
              <FirestoreAuthProvider>
                <CurrentPersonProvider>
                  <PortalRouter />
                </CurrentPersonProvider>
              </FirestoreAuthProvider>
            </LoginRequired>
            <Route path="*">
              <h1>404 page goes here</h1>
            </Route>
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
};

export default App;
