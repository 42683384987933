import styled from 'styled-components';

import { Colors } from 'constants/theme';

export const P1 = styled.p`
  color: ${Colors.grey6};
  font-family: 'EuclidCircularB', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  // margin: 0; // TODO: refactor so this can be applied; need to regression test everywhere P1 is used
`;
