import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getLocalStorage, setLocalStorage } from 'utils/localStorage';

import { IChallenge } from 'constants/challenges';

import { useCurrentPerson } from './currentPerson';

interface RoutineState {
  acceptedSmsTerms: boolean;
  challenge: IChallenge;
  cue: string;
  originPageName?: string;
  originPageRoute?: string;
  phone: string;
  termsPreviouslyAccepted: boolean;
  termsTimeStamp?: Date;
  time: string;
  timezone: string;
}

export interface RoutineContextState extends RoutineState {
  setRoutine: Dispatch<SetStateAction<RoutineState>>;
}

export const defaultRoutineState: RoutineContextState = {
  challenge: {} as IChallenge,
  cue: '',
  originPageName: undefined,
  originPageRoute: undefined,
  phone: '',
  time: '08:00',
  timezone: 'EST',
  termsPreviouslyAccepted: false,
  termsTimeStamp: undefined,
  acceptedSmsTerms: false,
  setRoutine: (() => {}) as Dispatch<SetStateAction<RoutineState>>
};

export const RoutineContext =
  React.createContext<RoutineContextState>(defaultRoutineState);

export const RoutineProvider: React.FC = (props) => {
  const currentPerson = useCurrentPerson();
  const state = getLocalStorage<RoutineState>('routine', defaultRoutineState);

  state.termsPreviouslyAccepted =
    !!currentPerson.routinesTextMessagePolicyAcceptedAt;
  state.acceptedSmsTerms = !!currentPerson.routinesTextMessagePolicyAcceptedAt;

  const [routine, setRoutine] = useState<RoutineState>(state);

  useEffect(() => {
    setLocalStorage<RoutineState>('routine', routine);
  }, [routine]);

  if (!props) {
    return null;
  }
  return (
    <RoutineContext.Provider
      value={{
        ...routine,
        setRoutine
      }}
    >
      {props.children}
    </RoutineContext.Provider>
  );
};
