export const REACT_APP_FIRESTORE_API_KEY =
  process.env.REACT_APP_FIRESTORE_API_KEY;

export const REACT_APP_FIRESTORE_PROJECT_ID =
  process.env.REACT_APP_FIRESTORE_PROJECT_ID;

export const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const STRIPE_HIGH_PRODUCT_ID =
  process.env.REACT_APP_STRIPE_HIGH_PRODUCT_ID;

export const STRIPE_MID_2_PRODUCT_ID =
  process.env.REACT_APP_STRIPE_MID_2_PRODUCT_ID;

export const STRIPE_MID_1_PRODUCT_ID =
  process.env.REACT_APP_STRIPE_MID_1_PRODUCT_ID;

export const STRIPE_LOW_PRODUCT_ID =
  process.env.REACT_APP_STRIPE_LOW_PRODUCT_ID;

export const REACT_APP_TWILIO_SERVICE_ID =
  process.env.REACT_APP_TWILIO_SERVICE_ID;

export const STRIPE_LAB_NY_PRICE_ID =
  process.env.REACT_APP_STRIPE_LAB_NY_PRICE_ID;

export const STRIPE_LAB_NON_NY_PRICE_ID =
  process.env.REACT_APP_STRIPE_LAB_NON_NY_PRICE_ID;

export const JOIN_APP_URL = process.env.REACT_APP_JOIN_APP_URL;

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const PARSLEY_WEBSITE = process.env.REACT_APP_PARSLEY_WEBSITE;

export const HABANERO_URL = process.env.REACT_APP_HABANERO_URL;
export const HABANERO_BEARER_TOKEN =
  process.env.REACT_APP_HABANERO_BEARER_TOKEN;

export const SANITY_DATASET = process.env.REACT_APP_SANITY_DATASET;
export const SANITY_PROJECT_ID = process.env.REACT_APP_SANITY_PROJECT_ID;
export const SANITY_TOKEN = process.env.REACT_APP_SANITY_TOKEN;
