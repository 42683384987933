/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

import { Colors } from 'constants/theme';

export const Link: React.FC<
  LinkProps<any> & React.RefAttributes<HTMLAnchorElement>
> = (props) => {
  return <Wrapper {...props}>{props.children}</Wrapper>;
};

export const LinkButton = styled.button`
  color: ${Colors.sage4};
  font-family: 'EuclidCircularB', sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  border: none;
  background-color: transparent;
  padding: 0;

  :hover {
    color: ${Colors.sage6};
    cursor: pointer;
  }

  :disabled {
    color: ${Colors.grey4};
    :hover {
      pointer-events: none;
      color: ${Colors.grey4};
    }
  }
`;

const Wrapper = styled(ReactRouterLink)`
  color: ${Colors.sage4};
  font-family: 'EuclidCircularB', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: underline;
`;
