/* eslint-disable typescript-sort-keys/string-enum */
enum Colors {
  /* eslint-disable typescript-sort-keys/string-enum */
  alert = '#D34531',
  black = '#000000',
  blush = '#EBCFB8',
  // Gradients
  gradient1 = '#DDE7EC',
  gradient2 = '#D0E4E2',
  gradient3 = '#C8E4DC',
  gradient4 = '#DBDEC1',
  gradient5 = '#ECD7A8',
  gradient6 = '#F2D59F',
  gradient7 = '#F0BBA4',
  gradient8 = '#ED9489',
  gradient9 = '#EC9085',
  gradient10 = '#EA7371',
  gradientInRange0 = '#D1DEDA',
  gradientInRange100 = '#93C0B2',
  gradientOutOfRange0 = '#DDBC8A',
  gradientOutOfRange100 = '#F2D59F',
  greatBlue = '#6483A0',
  // Grey
  grey1 = '#FFFFFF',
  grey2 = '#F9F9F9',
  grey3 = '#E8E8E8',
  grey4 = '#C4C4C8',
  grey5 = '#808080',
  grey6 = '#333333',
  lightgrey = '#EFEFEF',
  // Marigold
  marigold1 = '#F8F6F2',
  marigold2 = '#FCF0D7',
  marigold3 = '#EFC774',
  marigold4 = '#EFA245',
  marigold5 = '#BF7E3D',
  marigold6 = '#845C34',
  mild = '#F2D59F',
  //Poppy
  poppy1 = '#F3E7DD',
  poppy2 = '#EBCFB8',
  poppy3 = '#EA926D',
  poppy4 = '#E25D4B',
  poppy5 = '#D34531',
  poppy6 = '#AB373D',
  // Greens
  sage1 = '#EBF2F0',
  sage2 = '#D1DEDA',
  sage3 = '#7AAC9D',
  sage4 = '#4F7F71',
  sage5 = '#0F634A',
  sage6 = '#284849',
  // Purple
  purple = '#6d6d8f',
  // Slate
  slate1 = '#E2EEF6',
  slate2 = '#B3C9DE',
  slate3 = '#7AA4CB',
  slate4 = '#6483A0',
  slate5 = '#35648F',
  slate6 = '#2B445C',
  // Challenges Colors (old color library names)
  earlsGreen = '#B5B834',
  greenSmoke = '#A3B57D',
  hoki = '#627EA0',
  mongoose = '#BA9D81',
  whiskey = '#D89A6A',
  quicksand = '#BC8C94',
  rockBlue = '#9DA7CD',
  // Challenge Colors by ChallengeType
  supplementation = '#BA9D81',
  mindfulness = '#BC8C94',
  movement = '#D89A6A',
  sleep = '#A2A4D3',
  hydration = '#627EA0',
  nutrition = '#A3B57D',

  /*** New Design Library Colors ***/
  // Primary
  forest = '#284849',
  sage = '#4F7F71',
  clover = '#6FB29F',
  mint = '#C1E5DB',
  seafoam = '#EBF2F0',
  ivory = '#F8F6F2',
  // Secondary
  dustyRose = '#F5B3AA',
  blushNew = '#F1D9D6',
  peach = '#EBCFB8',
  lavender = '#D9D9FF',
  babyBlue = '#E2EEF6',

  // Health concers/Categories icons
  metabolism = '#EA926D',
  fertility = '#A2A4D3',
  undiagnosed = '#B3C9DE',
  pediatric = '#A2A4D3',
  hormonal = '#BC8C94',
  inflammation = '#627EA0',
  mental = '#D89A6A',

  // Challenge Colors on Quizzes
  supplementationQuiz = ivory,
  mindfulnessQuiz = blushNew,
  movementQuiz = peach,
  sleepQuiz = lavender,
  hydrationQuiz = babyBlue,
  nutritionQuiz = mint
}

enum Breakpoints {
  mobileS = 320,
  mobileM = 375,
  mobileL = 425,
  tablet = 768,
  laptop = 1024,
  laptopM = 1366,
  laptopL = 1440,
  desktop = 2560
}

const Device = {
  mobileS: `(min-width: ${Breakpoints.mobileS}px)`,
  mobileM: `(min-width: ${Breakpoints.mobileM}px)`,
  mobileL: `(min-width: ${Breakpoints.mobileL}px)`,
  tablet: `(min-width: ${Breakpoints.tablet}px)`,
  laptop: `(min-width: ${Breakpoints.laptop}px)`,
  laptopM: `(min-width: ${Breakpoints.laptopM}px)`,
  laptopL: `(min-width: ${Breakpoints.laptopL}px)`,
  desktop: `(min-width: ${Breakpoints.desktop}px)`
};

enum Spacing {
  l = '60px',
  lg = '80px',
  m = '30px',
  ml = '40px',
  s = '10px',
  sm = '20px'
}

enum SpacingNew {
  s = '15px'
}

enum Zindex {
  NavLinks = 2,
  CTA = 3,
  NavControl = 3, // signout and close buttons
  Card = 10,
  TopNav = 200,
  SideNav = 300,
  Modal = 1000
}

const SidebarWidth = '360px';

const TransitionCurves = {
  A: [0.0, 0.0, 1.0, 1.0], // linear motion, elements that are constant: timers, background gradient movement
  B: [0.0, 0.0, 0.05, 1.0], // revealing elements: load-ins, revealing hover states, navigation menu opening, revealing of buttons
  C: [0.24, 1.56, 0.59, 1.0], // disappearing elements: buttons disappearing, the ending of a hover state, closing the navigation menu
  D: [0.56, 0.0, 0.29, 1.0] // transitioning elements that are always visible: arrow rotation on click, the closing / opening of drawer components
};

enum Speeds { // in seconds
  interaction = 0.5,
  secondary = 0.7,
  loading = 1
}

export {
  Colors,
  Device,
  SidebarWidth,
  Spacing,
  Breakpoints,
  Zindex,
  TransitionCurves,
  Speeds,
  SpacingNew
};
