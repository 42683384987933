import { useCurrentPerson } from 'context/currentPerson';
import { LabsProvider } from 'context/labs';
import { LabsActivationProvider } from 'context/labsActivation';
import { RoutineProvider } from 'context/routine';
import LogRocket from 'logrocket';
import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { RouteItem } from 'types';

const DashboardLayout = React.lazy(
  () => import('components/layouts/DashboardLayout')
);
const QuestionnaireLayout = React.lazy(
  () => import('components/layouts/QuestionnaireLayout')
);
const RoutineLayout = React.lazy(
  () => import('components/layouts/RoutineLayout')
);
const LabOrderForm = React.lazy(
  () => import('components/molecules/LabOrderForm')
);
const CancelCheckout = React.lazy(
  () => import('components/pages/CancelCheckout')
);
const Checkout = React.lazy(() => import('components/pages/Checkout'));
const ConfirmPhonePage = React.lazy(
  () => import('components/pages/ConfirmPhonePage')
);
const LabKitActivation = React.lazy(
  () => import('components/pages/LabKitActivation')
);
const LabOrderSuccess = React.lazy(
  () => import('components/pages/LabOrderSuccess')
);
const LabsPage = React.lazy(() => import('components/pages/Labs'));
const RoutinesIntroPage = React.lazy(
  () => import('components/pages/RoutinesIntroPage')
);
const SectionDetailPage = React.lazy(
  () => import('components/pages/SectionDetailPage')
);
const SuccessCheckout = React.lazy(
  () => import('components/pages/SuccessCheckout')
);
const SuccessfulPhoneConfirmation = React.lazy(
  () => import('components/pages/SuccessfulPhoneConfirmation')
);

const Routine: React.FC = () => (
  <RoutineProvider>
    <RoutineLayout />
  </RoutineProvider>
);

const LabsActivation: React.FC = () => (
  <RoutineProvider>
    <LabsActivationProvider>
      <LabKitActivation />
    </LabsActivationProvider>
  </RoutineProvider>
);

const Labs: React.FC = () => (
  <RoutineProvider>
    <LabsProvider>
      <DashboardLayout />
    </LabsProvider>
  </RoutineProvider>
);

const LabsPrint: React.FC = () => (
  <RoutineProvider>
    <LabsProvider>
      <LabsPage print></LabsPage>
    </LabsProvider>
  </RoutineProvider>
);

export const routes: RouteItem[] = [
  {
    Component: QuestionnaireLayout,
    path: '/q',
    exact: false,
    title: 'Questionaires'
  },
  {
    Component: DashboardLayout,
    path: '/plan-detail',
    exact: false,
    title: 'Plan'
  },
  {
    Component: DashboardLayout,
    path: '/dashboard',
    exact: false,
    title: 'Dashboard'
  },
  {
    Component: DashboardLayout,
    path: '/account',
    exact: true,
    title: ''
  },
  {
    Component: Labs,
    path: '/labs',
    exact: true,
    title: 'Labs'
  },
  {
    Component: LabsPrint,
    path: '/labs-print',
    exact: true,
    title: 'Labs'
  },
  {
    Component: LabOrderForm,
    path: '/order',
    exact: false,
    title: 'Order'
  },
  {
    Component: LabOrderSuccess,
    path: '/success-order',
    exact: false,
    title: 'Succesful Order'
  },
  {
    Component: LabsActivation,
    path: '/activate/:kitId',
    exact: true,
    title: ''
  },
  {
    Component: DashboardLayout,
    path: '/order-labs',
    exact: false,
    title: 'Labs'
  },
  {
    Component: DashboardLayout,
    path: '/labs/:diagnosticReportId/:biomarkerSlug',
    exact: true,
    title: 'Biomarker Detail Page'
  },
  {
    Component: SectionDetailPage,
    path: '/detail/:sectionSlug',
    exact: true,
    title: 'Section Detail'
  },
  {
    Component: Checkout,
    path: '/checkout',
    exact: false,
    title: 'Checkout Subscription'
  },
  {
    Component: SuccessCheckout,
    path: '/success-checkout',
    exact: false,
    title: 'Succesful Checkout'
  },
  {
    Component: CancelCheckout,
    path: '/cancel-checkout',
    exact: false,
    title: 'Cancel Checkout'
  },
  {
    Component: Routine,
    path: '/routine',
    exact: false,
    title: ''
  },
  {
    Component: RoutinesIntroPage,
    path: '/routine-intro',
    exact: true,
    title: ''
  },
  {
    Component: ConfirmPhonePage,
    path: '/confirm-phone',
    exact: true,
    title: ''
  },
  {
    Component: SuccessfulPhoneConfirmation,
    path: '/confirm-phone-success',
    exact: true,
    title: ''
  }
];

const PortalRouter: React.FC = () => {
  const location = useLocation();
  const person = useCurrentPerson();

  useEffect(() => {
    if (person.id) {
      LogRocket.identify(person.id);
    }
  }, [person.id]);

  return (
    <Switch location={location}>
      {routes.map(
        ({ Component, exact, hide, path }) =>
          !hide && (
            <Route
              component={Component}
              exact={exact}
              key={path}
              path={path}
            ></Route>
          )
      )}
    </Switch>
  );
};

export default PortalRouter;
