import * as EmailValidator from 'email-validator';
import mailcheck from 'mailcheck';

export const validateFormatEmail = (value: string): boolean => {
  return EmailValidator.validate(value);
};

export const suggestEmail = (value: string): string => {
  const suggestedEmail = mailcheck.run({
    email: value
  });
  return suggestedEmail ? suggestedEmail?.full : '';
};
