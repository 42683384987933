// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useAuth } from '@parsleyhealth/front-end-utils';
import { Button } from 'components/atoms/Button';
import { Link } from 'components/atoms/Link';
import { P1 } from 'components/atoms/P1';
import { RippleLoader } from 'components/atoms/RippleLoader';
import { InputField } from 'components/molecules/InputField';
import firebase from 'firebase';
import React, { useState } from 'react';
import * as reactHookForm from 'react-hook-form';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import { validateFormatEmail, suggestEmail } from 'utils/validator';

import { Colors } from 'constants/theme';

type InviteUserPageForm = {
  email: string;
  password: string;
  passwordConfirmation: string;
};

const getEmailErrorMessage = ({
  errors
}: reactHookForm.FormState<InviteUserPageForm>) => {
  const emailErrors = errors.email;
  if (!emailErrors) return undefined;
  switch (emailErrors.type) {
    case 'required':
      return 'Email field is required';
    case 'validate':
      return emailErrors.message;
  }
};

const getPasswordErrorMessage = ({
  errors
}: reactHookForm.FormState<InviteUserPageForm>) => {
  const passwordErrors = errors.password;
  if (!passwordErrors) return undefined;
  switch (passwordErrors.type) {
    case 'minLength':
      return 'Passwords must be at least 8 characters';
    case 'required':
      return 'Must include password';
  }
};

const getPasswordConfirmationErrorMessage = ({
  errors
}: reactHookForm.FormState<InviteUserPageForm>) => {
  const passwordConfirmationErrors = errors.passwordConfirmation;
  if (!passwordConfirmationErrors) return undefined;
  switch (passwordConfirmationErrors.type) {
    case 'validate':
      return 'Passwords must match';
    case 'required':
      return 'Must include password confirmation';
  }
};

const validateEmail = (email: string) => {
  if (!validateFormatEmail(email)) return 'Please enter a valid email';
  if (suggestEmail(email)) return `Did you mean ${suggestEmail(email)}`;
  return true;
};

export const Signup: React.FC = () => {
  const { formState, getValues, handleSubmit, register } =
    reactHookForm.useForm<InviteUserPageForm>();
  const [error, setError] = useState<undefined | string>();
  const [showSignIn, setShowSignIn] = useState(false);
  const { isLoggedIn } = useAuth();

  const onSubmit = async ({ email, password }: InviteUserPageForm) => {
    setShowSignIn(false);
    window.heap.identify(email);
    await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .catch((error) => {
        if (error.code === 'auth/email-already-in-use') {
          setError(
            'An account already exists with this email address. Try signing in'
          );
          setShowSignIn(true);
        } else {
          setError(error.message);
        }
      });
  };

  if (isLoggedIn) {
    return <Redirect to={'/start'} />;
  }

  return (
    <MainLoginPage>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <Greeting>Insights by Parsley Health</Greeting>
        <P1>
          Sign up now to test out our new digital experience. Please use your
          mobile phone, either Android or iPhone.{' '}
        </P1>
        <Error>{error}</Error>
        <InputField
          autoComplete="email"
          bordered
          label="Email"
          type="email"
          {...register('email', {
            required: true,
            validate: validateEmail
          })}
          errorMessage={getEmailErrorMessage(formState)}
        />
        <M15 />
        <InputField
          autoComplete="password"
          bordered
          label="Password"
          type="password"
          {...register('password', {
            required: true,
            minLength: '8'
          })}
          errorMessage={getPasswordErrorMessage(formState)}
        />
        <M15 />
        <InputField
          autoComplete="password"
          bordered
          label="Retype Password"
          type="password"
          {...register('passwordConfirmation', {
            required: true,
            validate: (value) => {
              return value === getValues('password');
            }
          })}
          errorMessage={getPasswordConfirmationErrorMessage(formState)}
        />
        <SignUpButton onClick={handleSubmit(onSubmit)} variant="primary">
          {formState.isValidating || formState.isSubmitting ? (
            <RippleLoader />
          ) : (
            'Sign Up'
          )}
        </SignUpButton>
        {showSignIn && (
          <SignInBox>
            <P1>Already have a Parsley account? </P1>
            <Link to="/login">Sign in here</Link>
          </SignInBox>
        )}
      </Container>
    </MainLoginPage>
  );
};

const Error = styled.p`
  font-family: 'EuclidCircularB', sans-serif;
  font-size: 14px;
  color: ${Colors.poppy6};
`;

const MainLoginPage = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 30px;
  background-color: #fff;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Container = styled.form`
  max-width: 768px;
  width: 315px;
  display: flex;
  flex-direction: column;
`;

const Greeting = styled.h2`
  font-family: TeodorLight;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #333333;
`;

const SignUpButton = styled(Button)`
  margin-top: 40px;
  height: 50px;
  width: 196px;
  align-self: center;
`;

const M15 = styled.div`
  margin: 15px;
`;

const SignInBox = styled.div`
  width: 100%;
  text-align: center;
  a {
    color: ${Colors.sage4};
  }
`;

export default Signup;
