import { QueryResult, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import { getCustomFirestoreToken } from '../types/generated/getCustomFirestoreToken';

export const FIRESTORE_TOKEN = gql`
  query getCustomFirestoreToken {
    getFirestoreToken
  }
`;

const useGetFirestoreToken = (): QueryResult<getCustomFirestoreToken> =>
  useQuery<getCustomFirestoreToken>(FIRESTORE_TOKEN);

export default useGetFirestoreToken;
