import { LockIcon } from 'components/icons/LockIcon';
import React, { useEffect, useState, forwardRef } from 'react';
import { animated, useSpring } from 'react-spring';
import styled, { css } from 'styled-components';

import { Colors, Spacing } from 'constants/theme';

type InputFieldProps = JSX.IntrinsicElements['input'] & {
  bordered?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  hasMask?: boolean;
  label?: string;
  note?: string;
};

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      bordered = false,
      disabled = false,
      errorMessage,
      hasMask = false,
      id,
      label,
      note,
      type = 'text',
      value,
      ...props
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isEmpty, setIsEmpty] = useState(true);
    const { defaultValue } = props;

    useEffect(() => {
      if (typeof value === 'string') {
        setIsEmpty(!value.length);
      }
    }, [value]);

    const labelStyle = useSpring({
      fontSize:
        isFocused || !isEmpty || hasMask || defaultValue
          ? '10px'
          : bordered
          ? '16px'
          : '24px',
      lineHeight:
        isFocused || !isEmpty || hasMask || defaultValue
          ? '10px'
          : bordered
          ? '24px'
          : '30px',
      fontFamily:
        isFocused || !isEmpty || bordered || hasMask || defaultValue
          ? 'EuclidCircularB'
          : 'TeodorThin',
      top: isFocused || !isEmpty ? (bordered ? '0px' : '-4px') : '0px'
    });

    const inputStyles = useSpring({
      ...(bordered
        ? {
            borderColor: errorMessage
              ? Colors.poppy6
              : isFocused && !disabled
              ? Colors.sage4
              : Colors.grey5
          }
        : {
            borderBottomColor: errorMessage
              ? Colors.poppy6
              : isFocused && !disabled
              ? Colors.sage4
              : Colors.grey5
          })
    });

    const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
      props.onFocus?.(e);
      setIsFocused(true);
    };

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
      props.onBlur?.(e);
      setIsFocused(false);
    };

    const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      props.onChange?.(e);
      if (e.target.value.length) {
        setIsEmpty(false);
      } else {
        setIsEmpty(true);
      }
    };

    return (
      <InputContainer>
        {label ? (
          <InputLabel
            $bordered={bordered}
            $hasMask={hasMask}
            $isDefaultValue={Boolean(props.defaultValue)}
            $isEmpty={isEmpty}
            $isFocused={isFocused}
            data-testid="label"
            htmlFor={id}
            style={labelStyle}
          >
            {label}
          </InputLabel>
        ) : null}
        <Input
          {...(props as any)}
          $bordered={bordered}
          data-testid="input"
          disabled={disabled}
          id={id}
          onBlur={handleBlur}
          onChange={handleOnChange}
          onFocus={handleFocus}
          ref={ref}
          style={inputStyles}
          type={type}
          value={value}
        />
        {disabled && (
          <InputIcon>
            <Line />
            <LockIcon />
          </InputIcon>
        )}
        {errorMessage ? (
          <ErrorMessage data-testid="error">{errorMessage}</ErrorMessage>
        ) : null}
        {note ? <Note data-testid="note">{note}</Note> : null}
      </InputContainer>
    );
  }
);

const Input = styled(animated.input)<{
  $bordered: boolean;
  disabled: boolean;
}>`
  box-sizing: border-box;
  color: ${Colors.grey6};
  font-style: normal;
  outline: none;

  ${(props) =>
    props.$bordered
      ? css`
          border: 1px solid ${Colors.grey5};
          border-radius: 4px;
          padding: 15px 10px 5px;
          font-family: 'EuclidCircularB', sans-serif;
          font-size: 16px;
          line-height: 24px;
          transition: border-color 0.3s ease-in-out;
          &:hover {
            border-color: ${Colors.sage4}!important;
          }
        `
      : css`
          border: none;
          border-bottom: 1px solid;
          background: none;
          font-family: TeodorThin;
          font-weight: 300;
          font-size: 24px;
          line-height: 30px;
          height: 45px;
        `}

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${Colors.grey2};
      &:hover {
        border-color: ${Colors.grey5}!important;
      }
    `}
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  input[type='date']::-webkit-datetime-edit {
    color: transparent;
  }
  input[type='date']:valid::-webkit-datetime-edit {
    color: #000;
  }
  input[type='date']:focus::-webkit-datetime-edit {
    color: #000;
  }

  input[type='date'] {
    color: transparent;
  }
  input[type='date']:valid {
    color: #000;
  }
  input[type='date']:focus {
    color: #000;
  }
`;

const InputLabel = styled(animated.label)<{
  $bordered: boolean;
  $hasMask: boolean;
  $isDefaultValue: boolean;
  $isEmpty: boolean;
  $isFocused: boolean;
}>`
  position: absolute;
  color: ${Colors.grey5};
  pointer-events: none;
  ${(props) =>
    props.$bordered
      ? css`
          left: ${Spacing.s};
          top: 0;
          ${props.$isFocused ||
          !props.$isEmpty ||
          props.$hasMask ||
          props.$isDefaultValue
            ? css`
                text-transform: uppercase;
                padding-top: 5px;
              `
            : css`
                text-transform: none;
                padding-top: ${Spacing.s};
              `}
        `
      : css`
          font-size: 24px;
          left: 0;
          line-height: 30px;
          top: 0;
        `}
`;

const InputIcon = styled.div`
  position: absolute;
  right: 0;
  padding-right: ${Spacing.s};
  display: flex;
  flex-flow: row nowrap;
  top: 50%;
  transform: translateY(-50%);
  align-items: center;
  font-size: 20px;
  line-height: 20px;
`;

const Line = styled.div`
  height: 24px;
  width: 0px;
  border: 1px solid ${Colors.grey3};
  margin-right: ${Spacing.s};
`;

const ErrorMessage = styled.span`
  display: block;
  font-family: EuclidCircularB;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.poppy6};
  margin-top: 5px;
`;

const Note = styled.div`
  display: block;
  font-family: EuclidCircularB;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.grey5};
  margin-top: 5px;
`;
