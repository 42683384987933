import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getLocalStorage, setLocalStorage } from 'utils/localStorage';

interface LabsState {
  rangesShown: 'optimal' | 'standard';
}

export interface LabsContextState extends LabsState {
  setLabs: Dispatch<SetStateAction<LabsState>>;
}

export const defaultLabsState: LabsContextState = {
  rangesShown: 'optimal',
  setLabs: (() => {}) as Dispatch<SetStateAction<LabsState>>
};

export const LabsContext =
  React.createContext<LabsContextState>(defaultLabsState);

export const LabsProvider: React.FC = (props) => {
  const state = getLocalStorage<LabsState>('labs', defaultLabsState);

  const [labs, setLabs] = useState<LabsState>(state);

  useEffect(() => {
    setLocalStorage<LabsState>('labs', labs);
  }, [labs]);

  if (!props) {
    return null;
  }

  return (
    <LabsContext.Provider
      value={{
        ...labs,
        setLabs
      }}
    >
      {props.children}
    </LabsContext.Provider>
  );
};
