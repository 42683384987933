import styled, { css } from 'styled-components';

import { Colors } from 'constants/theme';

type ButtonProps = {
  inverted?: boolean;
  invertedGrey?: boolean;
  selected?: boolean;
  variant: 'primary' | 'secondary';
};

export const buttonReset = css`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export const Button = styled.button<ButtonProps>`
  ${buttonReset}
  background: ${Colors.sage4};
  border-radius: 100px;
  color: ${Colors.grey1};
  font-family: EuclidCircularB;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 20px;
  transition: all 0.3s ease-in-out;
  :hover {
    background-color: ${(props) =>
      props.inverted ? Colors.sage1 : Colors.sage6};
    color: ${(props) => (props.inverted ? Colors.sage4 : Colors.sage1)};
  }
  ${(props) =>
    props.inverted &&
    css`
      background-color: ${props.inverted ? Colors.grey1 : Colors.sage4};
      cursor: pointer;
      color: ${Colors.sage4};
      :hover {
        background-color: ${props.inverted ? Colors.grey1 : Colors.grey4};
      }
    `}
  ${(props) => css`
    :disabled {
      background-color: ${props.inverted ? Colors.sage4 : Colors.grey4};
      cursor: not-allowed;
      color: ${Colors.grey1};
      :hover {
        background-color: ${props.inverted ? Colors.sage4 : Colors.grey4};
        color: ${Colors.grey1};
        cursor: not-allowed;
      }
    }
  `}
`;

export const OutlineButton = styled(Button)<ButtonProps>`
  background-color: transparent;
  border: 1px solid
    ${(props) =>
      props.invertedGrey
        ? Colors.grey3
        : Colors.grey1
        ? Colors.sage4
        : Colors.sage4};
  color: ${(props) => (props.inverted ? Colors.grey1 : Colors.sage4)};
  padding: 8px 12px;
  :hover {
    background-color: ${(props) =>
      props.inverted ? Colors.sage6 : Colors.sage1};
    color: ${(props) => (props.inverted ? Colors.grey1 : Colors.sage4)};
  }
  ${(props) => css`
    :disabled {
      border-color: ${props.inverted ? Colors.grey1 : Colors.grey4};
      color: ${props.inverted ? Colors.grey4 : Colors.grey1};
      cursor: not-allowed;
      opacity: ${props.inverted ? 0.5 : 1};
      :hover {
        color: ${props.inverted ? Colors.grey4 : Colors.grey1};
      }
    }
  `}
  ${(props) =>
    props.selected
      ? css`
          background-color: ${props.inverted ? Colors.grey1 : Colors.sage6};
          color: ${props.inverted ? Colors.sage4 : Colors.grey1};
          :hover {
            background-color: ${props.inverted ? Colors.grey1 : Colors.sage6};
            color: ${props.inverted ? Colors.sage4 : Colors.grey1};
          }
        `
      : ''}
`;

export const pagePrimary = css`
  background-color: ${Colors.sage4};
  color: ${Colors.grey1};
  :hover {
    background-color: ${Colors.sage4};
    color: ${Colors.grey1};
  }
`;

export const pageSecondary = css`
  background-color: transparent;
  color: ${Colors.sage4};
  :hover {
    background-color: transparent;
    color: ${Colors.sage4};
  }
`;

export const pagePrimaryDisabled = css`
  background-color: ${Colors.grey4};
  color: ${Colors.grey1};
  :hover {
    background-color: ${Colors.grey4};
    color: ${Colors.grey1};
  }
`;

export const pageSecondaryDisabled = css`
  background-color: transparent;
  color: ${Colors.grey4};
  :hover {
    background-color: transparent;
    color: ${Colors.grey4};
  }
`;

export const PageButton = styled(Button)<ButtonProps>`
  border: 1px solid ${Colors.sage4};
  padding: 15px 40px;
  min-width: 130px;
  max-width: 280px;
  :hover {
    box-shadow: 0px 4px 40px 0px #00000059;
  }
  ${(props) => (props.variant === 'primary' ? pagePrimary : pageSecondary)}

  ${(props) => css`
    :disabled {
      border-color: ${Colors.grey4};
      cursor: not-allowed;
      :hover {
        box-shadow: none;
      }
      ${props.variant === 'primary'
        ? pagePrimaryDisabled
        : pageSecondaryDisabled};
    }
  `}
  ${(props) =>
    props.inverted
      ? css`
          border-color: ${Colors.grey4};
          :hover {
            box-shadow: ${props.disabled
              ? 'none'
              : '0px 4px 40px 0px #00000059'};
          }
          ${props.variant === 'primary'
            ? props.disabled
              ? css`
                  background-color: ${Colors.grey4};
                  color: ${Colors.grey1};
                  :hover {
                    background-color: ${Colors.grey4};
                    color: ${Colors.grey1};
                  }
                `
              : css`
                  background-color: ${Colors.grey1};
                  color: ${Colors.sage4};
                  :hover {
                    background-color: ${Colors.grey1};
                    color: ${Colors.sage4};
                  }
                `
            : props.disabled
            ? css`
                background-color: transparent;
                color: ${Colors.grey4};
                :hover {
                  background-color: transparent;
                  color: ${Colors.grey4};
                }
              `
            : css`
                background-color: transparent;
                color: ${Colors.grey1};
                :hover {
                  background-color: transparent;
                  color: ${Colors.grey1};
                }
              `};
        `
      : ''}
`;

export const modulePrimary = css`
  background-color: ${Colors.sage4};
  color: ${Colors.grey1};
  :hover {
    background-color: ${Colors.sage6};
    border-color: ${Colors.sage6};
  }
`;

export const moduleSecondary = css`
  background-color: ${Colors.grey1};
  color: ${Colors.sage4};
  :hover {
    background-color: ${Colors.sage1};
    color: ${Colors.sage4};
  }
`;

export const ModuleButton = styled(Button)<ButtonProps>`
  border: 1px solid ${Colors.sage4};
  padding: 8px 20px;
  min-width: 130px;
  max-width: 280px;
  ${(props) => (props.variant === 'primary' ? modulePrimary : moduleSecondary)}
  ${(props) => (props.disabled ? `background-color: ${Colors.grey4};` : '')}
  ${(props) =>
    props.selected
      ? css`
          background-color: ${props.variant === 'primary'
            ? Colors.sage6
            : Colors.sage1};
          border-color: ${props.variant === 'primary'
            ? Colors.sage6
            : Colors.sage4};
        `
      : ''}
  ${(props) =>
    css`
      :disabled {
        background-color: ${props.variant === 'primary'
          ? Colors.grey4
          : Colors.grey1};
        border-color: ${Colors.grey4};
        color: ${props.variant === 'primary' ? Colors.grey1 : Colors.grey4};
        cursor: not-allowed;
        :hover {
          background-color: ${props.variant === 'primary'
            ? Colors.grey4
            : Colors.grey1};
          border-color: ${Colors.grey4};
          color: ${props.variant === 'primary' ? Colors.grey1 : Colors.grey4};
        }
      }
    `}
`;
