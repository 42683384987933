export const setLocalStorage = <Type>(key: string, value: Type): void => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // TODO: catch possible errors:
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  }
};

export const getLocalStorage = <Type>(
  key: string,
  initialValue: Type
): Type => {
  try {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    return initialValue;
  }
};
